import { getConfig } from '@lib/prepr/config';
import { getHomePage } from '@lib/prepr/home';
import HomeTemplate from '@templates/HomeTemplate/HomeTemplate';
import { Locale } from '@type-declarations/locale';
import { HomePage as Props } from '@type-declarations/page';
import getAdditionalSectionData from '@utils/getAdditionalSectionData';

function HomePage({ page }: { page: Props }) {
  return <HomeTemplate page={page} />;
}

export async function getServerSideProps({
  req,
  locale,
  preview = false,
}: {
  req: { cookies: { __prepr_uid: string } };
  locale: Locale;
  preview?: boolean;
}) {
  const { __prepr_uid: customerId = '' } = req.cookies;
  const [page, config] = await Promise.all([
    getHomePage({ locale, customerId, preview }),
    getConfig({ locale, preview }),
  ]);

  if (!page) {
    return {
      notFound: true,
    };
  }

  let additionalSectionData = {};
  if (page?.sections?.length) {
    additionalSectionData = await getAdditionalSectionData(
      page.sections || [],
      page.id,
      locale
    );
  }

  return {
    props: {
      customerId,
      locale,
      preview,
      page: { ...page, additionalSectionData, heroVariant: 'large' },
      config,
    },
  };
}

export default HomePage;
